export default ({ backgroundposition, imageurl, theme }) => `
  height: 100vh;
  max-height: 600px;
  width:100%;
  background-image: url(${imageurl});
  background-size: 100%;
  background-position: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background-repeat: no-repeat;

  @media(max-width: 1400px) {
    background-size: cover;
    background-position: ${backgroundposition};
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 30px 0;

    @media(max-width: 820px) {
      margin: 10px 0;
    }
  }

  .compare-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: column;
    
    p {
      font-size: 25px !important;
      font-weight: bolder;
      margin: 0;
    }
  }



  .highlight-text {
    background-color: ${theme.alternateAccentColor};
    padding: 0px 3px;
  }

  .text-container {
    backdrop-filter: contrast(0.8);
    padding: 20px 40px 20px 60px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    color: #000;
    max-width: calc(40% - 100px);

    .calculation-details {
      font-size: 14px;
      margin-top: 40px;
      font-style: italic;
      font-weight: normal;
    }

    h1 {
      font-weight: bolder;
      text-transform: uppercase;
     /*  padding: 0px 10px; */
      color: #000;

      @media(min-width: 768px) {
        font-size: 40px;
      }
    }

    p {
      margin: 5px 0;
      font-weight: bolder;
      font-size: 18px;
    }

    @media(max-width: 769px) {
      /* color: #fff; */
      backdrop-filter: contrast(0.5);
      width: 100%;
      max-width: unset;
    }
  }
`;
