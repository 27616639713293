import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import GetExtensionStrip from 'SharedComponents/extension-install-strip';
import log from "Utils/logger";

import SuperCalcualtor from 'SharedComponents/super-calculator';
import ShareStrip from 'SharedComponents/share-strip';
//import ShopsStrip from 'SharedComponents/shops-strip';
//import LandingBanner from 'SharedComponents/landing-banner';
import LandingHero from 'SharedComponents/landing-hero';
import Footer from 'SharedComponents/footer';
import FAQ from 'SharedComponents/faq';
//import ShopsCategories from 'SharedComponents/shops-categories';
import HowItWorksTestimonials from 'SharedComponents/how-it-works-testimonials';
import HowItWorks from 'SharedComponents/how-it-works';
import Testimonials from 'SharedComponents/testimonials';
import BlackFridayBanner from 'SharedComponents/black-friday-banner';
import CharityBanner from 'SharedComponents/charity-banner';
import TrustPilotReviews from 'SharedComponents/trust-pilot-reviews';
import AboutUs from 'SharedComponents/about-us';
import SignUpHero from 'SharedComponents/sign-up-hero';
import WhyUs from 'SharedComponents/why-us';
import WhoCanUseBYS from 'SharedComponents/who-can-use-bys';
import WhatIsBoostYourSuper from 'SharedComponents/what-is-bys';
import JoinBYS from 'SharedComponents/join-bys';
import AsSeenOnBannerWrapper from 'SharedComponents/as-seen-on-banner';
import FundsWePayTo from 'SharedComponents/banner-funds-we-pay-to';
import RoundUpEffect from 'SharedComponents/banner-round-up-effect';
import WhoWeBoost from 'SharedComponents/banner-who-we-boost';
import MoreOnRoundUps from 'SharedComponents/banner-more-on-round-ups';

import styles from './index.css';

import CONFIG from '../../../config';

import useFetchStores from "SharedHooks/fetch-stores";
import useFetchTestimonials from "SharedHooks/fetch-testimonials";
import useFetchLandingPageContent from "SharedHooks/fetch-landing-page-content";
import useCheckExtensionInstalled from "SharedHooks/check-extension-installed";


const LandingWrapper = styled.div`${styles}`;

const Landing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);
  //const shopping = useSelector((state) => state.shopping);
  const user = useSelector((state) => state.authentication.user);
  //const testimonials = useSelector((state) => state.testimonials);
  const landingPageContent = useSelector((state) => state.app.landingPageContent);
  const testimonials = useSelector((state) => state.testimonials);


  const [localLandingPageContent, setLocalLandingPageContent] = useState(
    landingPageContent ? landingPageContent : null
  );
  const [localTestimonials, setLocalTestimonials] = useState(
    testimonials ? testimonials : null
  );

  const { fetchLandingPageContent } = useFetchLandingPageContent();
  const { fetchStores } = useFetchStores();
  const { fetchTestimonials } = useFetchTestimonials();
  const { checkExtensionInstalled } = useCheckExtensionInstalled();

  useEffect(() => {
    console.log('*** * Landing: useEffect triggered with landingPageContent:', landingPageContent);
    if (landingPageContent) {
      setLocalLandingPageContent(landingPageContent);
    }
  }, [landingPageContent]);

  useEffect(() => {
    console.log('*** * Landing: useEffect triggered with testimonials:', testimonials);
    if (testimonials) {
      setLocalTestimonials(testimonials);
    }
  } , [testimonials]);
  
  return (
    <LandingWrapper>
      { !localLandingPageContent
        ? <h2>Loading...</h2>
        : ( <div>
              <LandingHero content={localLandingPageContent} />
              <HowItWorks content={localLandingPageContent} />
              <FundsWePayTo />
              <RoundUpEffect content={localLandingPageContent} />
              {/* <WhatIsBoostYourSuper content={localLandingPageContent} /> */}
              <SuperCalcualtor content={localLandingPageContent} />  
              <WhoWeBoost content={localLandingPageContent} />
              {/* <WhoCanUseBYS content={localLandingPageContent} /> */}
              <Testimonials content={localLandingPageContent} />
              <MoreOnRoundUps content={localLandingPageContent} />
              <AsSeenOnBannerWrapper />
              <WhyUs content={localLandingPageContent} />
              <CharityBanner content={localLandingPageContent} />
              <JoinBYS content={localLandingPageContent} />
              <AboutUs content={localLandingPageContent} />
              <FAQ />
              <Footer />
            </div>
          )
      }
    </LandingWrapper>
  );
  
};

export default Landing;