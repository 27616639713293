import React, { useState } from 'react';
import styled from 'styled-components';

import Footer from 'SharedComponents/footer';
import Tabs from 'SharedComponents/tabs';

import styles from './index.css';

const PartnershipsWrapper = styled.section`${styles}`;


const Partnerships = () => {
  const [activeTab, setActiveTab] = useState('superfunds');
  return (
    <PartnershipsWrapper>
      <div className="partnerships-content">
        <h2>Partner with Boost Your Super</h2>
        <Tabs tabs={[{ label: 'Superfunds', value: 'superfunds' }, { label: 'Merchants', value: 'merchants' }, { label: 'Financial Advisors', value: 'financial-advisors' }]} activeTab={activeTab} onActiveTabChanged={(nextTab) => setActiveTab(nextTab)} />
        <div className="content-container" style={{ minHeight: 'calc(100vh - 400px)', width: 'calc(100% - 20px)' }}>
          {
            activeTab === 'superfunds' ? (
              <div>
                <h2>Engage Your Members</h2>
                <p>Partner with us to provide more meaningful engagement with your members</p>
                <ul>
                  <li>Bring your brand to your members daily</li>
                  <li>Help your members learn and earn more super</li>
                </ul>
                <p>Contact us for a super member engagement pack at accounts@boostyoursuper.com.au.</p>
              </div>
            ) : activeTab === 'merchants' ? (
              <div>
                <h2>Join our list of partner stores</h2>
                <p>Feature your store on our website by  joining one of our partner affiliate networks.</p>
                <p>You can join Commission Factory <a href="https://www.commissionfactory.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', fontWeight: 'bolder' }}>here</a>.</p>
                </div>
            ) : (
              <div>
                <h2>Support our Hacks 4 Your Super series</h2>
                <p>We work with Financial Advisors whom specialise in superannuation. Contact us about participating in our <span font="bold">Hacks 4 Your Super </span>email series where we cover tips and tricks that can help our users to retire with more.</p>
                <p>Contact us at <a href="mailto:accounts@boostyoursuper.com.au" target="_blank">accounts@boostyoursuper.com.au</a>.</p>
              </div>
            )
          }
        </div>
      </div>
      <Footer />
    </PartnershipsWrapper>
  );
}

export default Partnerships;
