export default ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


  .partnerships-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1366px;
    padding: 20px;
    color: #000;

    h2 {
      font-weight: bolder;
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;
    }

    a {
      color: #000;
      text-decoration: underline;
    }
  }
  

  .content {
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;

    .content-container {
      width: calc(100% - 20px);
      padding: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin: 10px 0 40px;

      section {
      }

      h4 {
        color: ${theme.darkSecondaryColor};
      }

      p {
        color: ${theme.lightSecondaryColor};
      }
    }
  }
`;
